<template>
  <section v-if="!slice.primary.hide_section" class="chat-callout large-pad">
    <div class="row">
      <div class="column small-12 xlarge-4 xxlarge-3">
        <Gees :gees="gees" imageSize="100" />
      </div>
      <div class="column small-12 large-8 xlarge-5 xxlarge-6">
        <h2 v-if="slice.primary.title">
          <prismic-rich-text :field="slice.primary.title" />
        </h2>
        <prismic-rich-text
          v-if="slice.primary.description"
          :field="slice.primary.description"
        />
      </div>
      <div class="column small-12 large-4 xlarge-3">
        <Button :text="slice.primary.button_text" @click="startChat" />
      </div>
    </div>
  </section>
</template>

<script>
  import { fetchAllGees } from "@/prismic/queries";

  import Gees from '@/components/global/Gees';
  import Button from '@/components/global/Button';

  export default {
    name: 'HomepageImageMultiCallout',

    components: {
      Gees,
      Button
    },

    data() {
      return {
        gees: null
      }
    },

    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    },

    methods: {
      startChat() {
        this.$liveChat.update({ current_page: window.location.href });

        this.$liveChat.show();
      }
    },

    async fetch() {
      try {
        const { data } = await this.$apolloProvider.defaultClient.query({
          query: fetchAllGees
        });

        const response = data.allAuthors.edges;

        if(response.length) {
          this.gees = response.length ? response.map(item => item.node) : null
        }
      } catch(err) {
        console.log(err);
      }
    }
  }
</script>
